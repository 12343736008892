*:focus
    outline: none !important
    box-shadow: none !important

.form-control:focus
    border: 1px solid #ced4da !important

datalist
    display: inline-flex
    justify-content: space-between
    width: 100%

#main
    display: flex
    flex-direction: column-reverse

    .map
        width: 100%
        height: 80vh
        border: 1px solid #e9ecef

#search
    display: flex
    flex-direction: row-reverse
    &-position
        width: 100%
    &-area
        min-width: 20%
        margin: 8px 24px 0 24px
